/* eslint-disable react/jsx-no-comment-textnodes */
"use client";

import { User } from "next-auth";
import { bizUDPGothic } from "@/lib/utils/fonts";
import { useGroupContext } from "@/context/GroupContextProvider";
import { usePathname } from "next/navigation";
import { useEffect } from "react";
import Footer from "./Footer";
import { signOut as nextAuthSignOut, useSession } from "next-auth/react";
import { adjustViewport } from "@/lib/utils";
import { deleteAllCookies } from "@/lib/utils/cookie-utils";
import { useUserGetProfile } from "@/hooks/user/useUserGetProfile";
import { useTranslation } from "@/lib/react/i18n";

export default function App({
  children,
}: Readonly<{
  children: React.ReactNode;
  user: User | undefined;
}>) {

  adjustViewport(375);

  const pathname = usePathname();
  const { setCurrentGroup } = useGroupContext();
  const { locale } = useTranslation();
  const groupMypageRegex = /^\/group\/mypage\/\d+$/;
  const { data: session } = useSession();
  const user = session?.user;
  const { setLocale, } = useTranslation();
  const { data, isLoading } = useUserGetProfile({
    userUuid: user?.id ?? ''
  });

  // 認証チェック
  useEffect(() => {
    async function handleSignOut() {
      if (!isLoading && data) {
        // ユーザーデータが存在しない場合はセッションクリアとログアウト
        if (Object.keys(data).length < 1) {
          // NextAuthのログアウト
          await nextAuthSignOut({
            redirect: false,
          });

          // クッキー & ローカルストレージ削除
          deleteAllCookies();
          localStorage.clear();
        }
        else {
          if (!data.locale) {
            return;
          }
          setLocale(data.locale);
        }
      }
    }

    handleSignOut();
  }, [data]);


  useEffect(() => {
    // `/group/`で始まるパスにマッチしない場合はグループページでないので初期化する
    if (!/^\/group\//.test(pathname)) {
      setCurrentGroup(null);
    }
  }, [pathname])

  return (
    <html lang={locale}>
      <head>
        {/* Google Tag Manager - Head */}
        <script
          async
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-NGBQMC5N');`,
          }}
        />
        <meta property="og:image" content="/public/og_image.jpg" />
        <meta name="disable-clipboard-notification" content="true"></meta>
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent"></meta>
        <link
          rel="manifest"
          href="/manifest.json"
          crossOrigin="use-credentials"
        />
        <link rel="icon" type="image/x-icon" href="/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/picsle_mark.jpg"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/picsle_mark.jpg"
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin=""
        />
        {/* Custom font link moved to pages/_document.js */}
      </head>
      <body
        className={`${bizUDPGothic.className} flex flex-col min-h-screen bg-black`}
      >
        {/* Google Tag Manager (noscript) */}
        <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NGBQMC5N"
          height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}></iframe></noscript>
        {/* End Google Tag Manager (noscript) */}
        <div className={`flex flex-col px-4 pc-container ${groupMypageRegex.test(pathname) ? 'bg-purple-500' : ''}`}>
          <main>{children}</main>
        </div>
      </body>
    </html>
  );
}
